import React from 'react';
import BrandPromotion from '../components/BrandPromotion';
import Offers from '../components/Offers';
import Products from '../components/Products';
import HomeAbout from '../components/HomeAbout';
import KeyProcess from '../components/KeyProcess';

const Home = () => {
  return (
    <>
      <BrandPromotion />
      <HomeAbout />
      <KeyProcess />
      {/* <Offers /> */}
      {/* <Products /> */}
    </>
  );
};

export default Home;
