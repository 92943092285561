import React, { useState } from 'react';
import { CustomInput } from '../components/CustomInput';
import { Formik, FormikHelpers } from 'formik';
import { CustomTextarea } from '../components/CustomTextarea';
import * as Yup from 'yup';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

interface IContactForm {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  message: string;
}

const FORM_INITIAL_VALUES = { first_name: '', last_name: '', email: '', phone: '', message: '' };

export const ContactUs = () => {
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const sendMailWithEmailJs = async (values: IContactForm) => {
    try {
      const data = {
        service_id: 'service_u3ti3gk',
        template_id: 'template_2yh2xhr',
        user_id: 'NJppkrc9x99mmwwDW',
        template_params: {
          from_name: values.first_name + ' ' + values.last_name,
          first_name: values.first_name,
          last_name: values.last_name,
          message: values.message,
          email: values.email,
          mobile_number: values.phone,
        },
      };
      const resEmail = await axios.post('https://api.emailjs.com/api/v1.0/email/send', data);
      if (resEmail.status === 200) {
        alert('Thank you for reaching out to us. Our team will be in touch with you shortly.');
        return true;
      } else {
        alert('Something went wrong! Try again after sometime.');
      }
    } catch (e) {
      alert('Something went wrong! Try again after sometime.');
    }
    return false;
  };

  const handleSendMail = async (values: IContactForm, helper: FormikHelpers<IContactForm>) => {
    if (!(recaptchaRef && recaptchaRef.current && recaptchaToken)) {
      alert('Please verify that you are not a robot!');
      return;
    }

    if (recaptchaToken) {
      helper.setSubmitting(true);
      const isSent = await sendMailWithEmailJs(values);
      helper.setSubmitting(true);
      if (isSent) {
        helper.resetForm();
        setRecaptchaToken(null);
        recaptchaRef.current.reset();
      }
    }
  };

  const onRecaptchaChange = (value: string | null) => {
    setRecaptchaToken(value);
  };

  return (
    <div>
      <div className="w-full flex justify-center">
        <div className="w-full max-w-[500px]">
          <Formik
            initialValues={FORM_INITIAL_VALUES}
            validationSchema={Yup.object().shape({
              first_name: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'),
              last_name: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'),
              email: Yup.string().email().required('Required'),
              phone: Yup.string().required('Required').min(10, 'Too Short!').max(20, 'Too Long!'),
              message: Yup.string()
                .min(2, 'Too Short!')
                .max(1000, 'Too Long!')
                .required('Required'),
            })}
            onSubmit={(values, helper) => handleSendMail(values, helper)}
          >
            {(formikProps) => {
              return (
                <div className="pt-16 px-8">
                  <CustomInput fieldName="first_name" label={'First Name'} {...formikProps} />
                  <CustomInput fieldName="last_name" label={'Last Name'} {...formikProps} />
                  <CustomInput fieldName="email" label={'Email'} {...formikProps} />
                  <CustomInput fieldName="phone" label={'Mobile Number'} {...formikProps} />
                  <CustomTextarea
                    className={'!min-h-[180px]'}
                    fieldName="message"
                    label="Message"
                    {...formikProps}
                  />
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Le1AUkpAAAAAApUi85P7bkrq8u4IFIpaWeZOCbt"
                    onChange={onRecaptchaChange}
                  />
                  <div className="w-full flex justify-center mt-8">
                    <div
                      className="btn-primary cursor-pointer !flex"
                      onClick={() => formikProps.handleSubmit()}
                    >
                      {formikProps.isSubmitting ? <div className="loader"></div> : 'Submit'}
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
