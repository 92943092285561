import React, { useEffect } from 'react';
import ProductPreview from '../components/ProductPreview';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../components/BreadCrumb';

const ProductDetailPage = () => {
  const params = useParams();

  const breadCrumbProps = {
    breadCrumb: [{ label: 'Home', navigationUrl: '/' }, { label: 'Product' }],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <BreadCrumb {...breadCrumbProps} /> */}
      <ProductPreview />
    </>
  );
};

export default ProductDetailPage;
