import { FormikProps } from 'formik';
import React from 'react';

interface ICustomTextarea<T>
  extends FormikProps<T>,
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    > {
  fieldName: keyof T;
  label: string;
}

export const CustomTextarea = <T extends object>(props: ICustomTextarea<T>) => {
  const { fieldName, label, handleChange, handleBlur, values, errors, touched, ...inputProps } =
    props;
  return (
    <div className="mb-4">
      <div className="typo-title">{label}</div>
      <textarea
        {...inputProps}
        name={fieldName as string}
        className={`border-2 px-3 py-2 rounded-[4px] h-[48px] w-full focus:outline-none border-skin-on-surface focus:border-skin-secondary ${inputProps.className}`}
        placeholder={label}
        value={values[fieldName] as any}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {touched[fieldName] && errors[fieldName] && (
        <div className="typo-error">{errors[fieldName] as any}</div>
      )}
    </div>
  );
};
