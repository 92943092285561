import React from 'react';

const AboutImage01 = (forSm: boolean) => {
  return (
    <div
      className={`col-span-1 h-full items-center ${forSm ? 'block sm:hidden' : 'hidden sm:flex'}`}
    >
      <img
        src={require('../assets/images/home-about.webp')}
        alt={'img'}
        style={{ borderRadius: '0px' }}
      />
    </div>
  );
};

const AboutImage02 = () => {
  return (
    <div className={`col-span-1 h-full flex items-center`}>
      <img
        src={require('../assets/images/home-about.webp')}
        alt={'img'}
        style={{ borderRadius: '0px' }}
      />
    </div>
  );
};

const HomeAbout = () => {
  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 pt-16 md:pt-28">
        {AboutImage01(true)}
        <div className="col-span-1 h-full flex items-center sm:pr-20">
          <div className="py-4">
            <div className="typo-title pb-2">FIRST OF ALL</div>
            <div className="typo-display4 md:typo-display3 pb-4 md:pb-7">
              Discover the Beauty Within
            </div>
            <div className="typo-body-lg md:typo-display6-regular">
              Indulge in the art of self-care with our exclusive collection of high-quality cosmetic
              products. From luxurious skincare to glamorous makeup, Pexim Cosmetics has everything
              you need to enhance your natural beauty.
            </div>
          </div>
        </div>
        {AboutImage01(false)}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 pt-16 md:pt-32">
        {AboutImage02()}
        <div className="col-span-1 h-full flex items-center sm:pl-20">
          <div className="py-4">
            <div className="typo-title pb-2">AND LET'S NOT FORGET</div>
            <div className="typo-display4 md:typo-display3 pb-4 md:pb-7">
              Unleash Your Inner Glow
            </div>
            <div className="typo-body-lg md:typo-display6-regular">
              Experience the transformative power of Pexim Cosmetics. Our carefully crafted products
              are designed to unleash your inner glow and radiate confidence. Discover the perfect
              blend of science and beauty with our innovative formulas and indulge in a luxurious
              self-care routine.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
