import React from 'react';
import CollapsiblePanel from './CollapsiblePanel';

const POINTS = [
  {
    label: 'Quality First',
    description:
      'At Pexim Cosmetics, we prioritize quality above all else. Our products are carefully crafted using the finest ingredients to ensure optimal results and customer satisfaction.',
  },
  {
    label: 'Enhancing Natural Beauty',
    description:
      'We believe in enhancing your natural beauty rather than masking it. Our cosmetic products are designed to accentuate your best features and bring out your unique radiance.',
  },
  {
    label: 'Self-Care at its Finest',
    description:
      'Indulge in the art of self-care with Pexim Cosmetics. Our collection offers a luxurious experience that allows you to pamper yourself and prioritize your well-being.',
  },
  {
    label: 'Stay Hydrated',
    description:
      'Drink an adequate amount of water to keep your skin hydrated from within. Hydration is crucial for maintaining skin elasticity and preventing dryness.',
  },
  {
    label: 'Moisturize Regularly',
    description:
      'Use a moisturizer suitable for your skin type. Moisturizing helps prevent dryness, flakiness, and can contribute to a healthy skin barrier. Apply moisturizer after bathing or washing your face.',
  },
];
const KeyProcess = () => {
  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-3 pt-16 md:pt-24">
        <div className="col-span-1">
          <div>
            <img
              src={require('../assets/images/product.webp')}
              alt={'img'}
              style={{ objectFit: 'cover', width: '100%' }}
            />
            <div className="typo-body-lg md:typo-display6-regular pt-4">
              Worldwide, 70% acknowledge skin sensitivity. In collaboration with dermatologists, our
              products combat 5 crucial signs, clinically tested for effective, resilient skincare.
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <div className="pt-3 sm:pl-20">
            {POINTS.map((keyPoint, index) => (
              <div key={`key-${index}`}>
                <CollapsiblePanel
                  index={index + 1}
                  containerClassName={'py-7'}
                  label={keyPoint.label}
                  component={<div className="typo-body">{keyPoint.description}</div>}
                />
              </div>
            ))}
            <div className="w-full border-t-skin-secondary border-t-[1px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyProcess;
