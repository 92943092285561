const ANTI_ACNE_FACE_WASH = {
  name: 'Anti-Acne Face Wash',
  code: 'anti-acne-face-wash',
  image: require('./../assets/images/product-img.png'),
  description:
    'Say goodbye to blemishes and hello to clear, radiant skin with Pexim Cosmetic Anti-Acne Face Wash. Formulated with a powerful blend of natural ingredients, this gentle yet effective face wash is designed to target acne, unclog pores, and leave your skin feeling refreshed.',
  key_feature: [
    {
      title: 'Acne-Fighting Formula',
      text: 'Pexim Cosmetic Face Wash combines the potency of salicylic acid with soothing botanical extracts to combat acne at its source, promoting a clear complexion.',
    },
    {
      title: 'Gentle Cleansing',
      text: 'Our mild, non-drying formula ensures a thorough cleanse without stripping your skin of its natural moisture, making it suitable for daily use.',
    },
    {
      title: 'Deep Pore Cleansing',
      text: 'The advanced formula penetrates deep into pores to remove impurities, excess oil, and debris, preventing future breakouts.',
    },
    {
      title: 'Refreshing Sensation',
      text: 'Experience a revitalizing sensation as you cleanse, leaving your skin feeling invigorated and rejuvenated after each use.',
    },
    {
      title: 'Suitable for All Skin Types',
      text: 'Whether you have oily, combination, or sensitive skin, Pexim Cosmetic Anti-Acne Face Wash is gentle enough for all skin types.',
    },
  ],
  direction_for_use: [
    'Wet your face with lukewarm water.',
    'Apply a small amount of Pexim Cosmetic Anti-Acne Face Wash to your fingertips.',
    'Gently massage onto your face in circular motions, avoiding the eye area.',
    'Rinse thoroughly and pat your face dry.',
    'Follow up with your favourite moisturizer.',
  ],
  additional_text:
    'Make Pexim Cosmetic Anti-Acne Face Wash a staple in your daily skincare routine for a clearer, healthier complexion.',
};

const BRIGHTENING_CREAM = {
  name: 'Brightening Cream',
  code: 'brightening-cream',
  image: require('./../assets/images/product-img.png'),
  description:
    "Illuminate your complexion with the transformative power of Pexim Cosmetic Brightening Cream. Formulated to unveil your skin's natural radiance, this cream combines potent brightening agents to address dark spots, uneven skin tone, and leave you with a luminous, glowing complexion.",
  key_feature: [
    {
      title: 'Advanced Brightening Complex',
      text: 'Pexim Cosmetic Brightening Cream boasts a powerful blend of brightening agents that target hyperpigmentation and discoloration, promoting a more even skin tone.',
    },
    {
      title: 'Vitamin E Boost',
      text: 'built-in sun protection, this cream helps shield your skin from harmful UV rays, preventing further sun-induced damage',
    },
    {
      title: 'Hydration Infusion',
      text: 'The brightening cream is infused with hydrating elements that nourish and moisturize the skin, leaving it supple and radiant.',
    },
    {
      title: 'Lightweight and Non-Greasy',
      text: 'Experience the luxury of a cream that glides on effortlessly, absorbing quickly without leaving a heavy residue, making it suitable for daily use.',
    },
    {
      title: 'Gentle on the Skin',
      text: 'Pexim Cosmetic understands the importance of gentle skincare. This brightening cream is suitable for all skin types, including sensitive skin.',
    },
    {
      title: 'Revitalizing Formula',
      text: 'Renew and revitalize your skin with a cream that not only brightens but also promotes a youthful and refreshed appearance.',
    },
  ],
  direction_for_use: [
    'Cleanse your face and pat it dry.',
    'Apply a small amount of Pexim Cosmetic Brightening Cream to your face and neck.',
    'Gently massage the cream in upward, circular motions until fully absorbed.',
    'Use morning, evening and night for best results.',
  ],
  additional_text:
    'Embrace a brighter, more radiant complexion with Pexim Cosmetic Brightening Cream. Let your skin glow with confidence and reveal the beautiful luminosity within.',
};

const D_TAN_SCRUB = {
  name: 'D-Tan Scrub',
  code: 'd-tan-scrub',
  image: require('./../assets/images/product-img.png'),
  description:
    "Reveal your skin's natural radiance with Pexim Cosmetic D-Tan Scrub. Specially crafted to exfoliate, brighten, and rejuvenate, this scrub is your secret weapon against dull and tired-looking skin.",
  key_feature: [
    {
      title: 'Deep Exfoliation',
      text: 'Pexim Cosmetic D-Tan Scrub features fine exfoliating particles that gently buff away dead skin cells, revealing a smoother and brighter complexion.',
    },
    {
      title: 'Tan Removal Formula',
      text: 'Enriched with potent ingredients, this scrub is designed to help reduce the appearance of sun-induced tanning, leaving your skin looking fresh and revitalized.',
    },
    {
      title: 'Nourishing Botanicals',
      text: 'Infused with nourishing botanical extracts, the scrub pampers your skin, leaving it feeling soft and supple after each use.',
    },
    {
      title: 'Anti-Oxidant Protection',
      text: 'The scrub is fortified with antioxidants to help protect your skin from environmental damage and promote a youthful, radiant complexion.',
    },
    {
      title: 'Refreshing Fragrance',
      text: 'Indulge in the invigorating scent as you massage the scrub onto your skin, making your skincare routine a sensory delight.',
    },
    {
      title: 'Suitable for All Skin Types',
      text: 'Whether you have dry, oily, or sensitive skin, Pexim Cosmetic D-Tan Scrub is formulated to suit a variety of skin types.',
    },
  ],
  direction_for_use: [
    'Dampen your face with water.',
    'Apply a small amount of Pexim Cosmetic D-Tan Scrub onto your fingertips.',
    'Gently massage the scrub onto your face and neck in circular motions, avoiding the eye area.',
    'Pay extra attention to areas with tan or uneven skin tone.',
    'Rinse thoroughly with water.',
    'Use 2-3 times a week for best results.',
  ],
  additional_text:
    'Unveil a brighter, more radiant complexion with Pexim Cosmetic D-Tan Scrub. Elevate your skincare routine and let your skin glow with renewed vitality.',
};

const MOISTURIZING_LOTION_ENRICHED_WITH_ALMOND_AND_HONEY = {
  name: 'Moisturizing Lotion Enriched with Almond and Honey',
  code: 'moisturizing-lotion-enriched-with-almond-and-honey',
  image: require('./../assets/images/product-img.png'),
  description:
    'Indulge your skin in the rich nourishment of Pexim Cosmetic Moisturizing Lotion, a luxurious blend infused with the goodness of sweet almond and honey. This replenishing lotion is designed to hydrate, soothe, and leave your skin feeling irresistibly soft and supple.',
  key_feature: [
    {
      title: 'Intense Hydration',
      text: "Pexim Cosmetic Moisturizing Lotion provides deep, long-lasting hydration, helping to restore your skin's natural moisture balance.",
    },
    {
      title: 'Almond Enrichment',
      text: 'Infused with almond oil, known for its emollient properties, the lotion helps soften and smoothen the skin, leaving it velvety to the touch.',
    },
    {
      title: "Honey's Healing Touch",
      text: 'Harnessing the natural benefits of honey, this lotion offers gentle healing and soothing properties, making it ideal for dry or irritated skin.',
    },
    {
      title: 'Non-Greasy Formula',
      text: 'The lightweight and non-greasy texture of the lotion absorbs quickly, providing instant relief without leaving a heavy residue.',
    },
    {
      title: 'Delicate Fragrance',
      text: 'Enjoy a subtle and delightful fragrance that lingers, imparting a sense of indulgence and freshness.',
    },
    {
      title: 'Skin Radiance',
      text: 'The combination of almond and honey promotes a radiant complexion, giving your skin a healthy and natural smoothness.',
    },
    {
      title: 'Versatile Use',
      text: 'Suitable for use on the face and body, this moisturizing lotion is a versatile addition to your daily skincare routine.',
    },
    {
      title: 'Paraben-Free and Dermatologist Tested',
      text: 'Committed to clean beauty, our formula is free from parabens, and the lotion is dermatologist-tested to ensure its safety and effectiveness.',
    },
  ],
  direction_for_use: [
    'Apply Pexim Cosmetic Moisturizing Lotion to clean, dry skin.',
    'Gently massage the lotion in circular motions until fully absorbed.',
    'For best results, use daily as part of your morning and evening skincare routine.',
  ],
  additional_text:
    'Pamper your skin with the natural goodness of almond and honey. Experience the luxury of Pexim Cosmetic Moisturizing Lotion and embrace skin that feels beautifully nourished, soft, and radiant.',
};

const NIACINAMIDE_FACE_SERUM = {
  name: '10% Niacinamide Face Serum',
  code: 'niacinamide-face-serum',
  image: require('./../assets/images/product-img.png'),
  description:
    'Unleash the power of flawless skin with Pexim Cosmetic 10% Niacinamide Face Serum. Carefully crafted to address a variety of skin concerns, this serum is a potent solution for achieving a smoother, clearer complexion.',
  key_feature: [
    {
      title: 'High Potency Niacinamide',
      text: "Pexim Cosmetic's serum boasts a concentrated 10% niacinamide (Vitamin B3) formulation, renowned for its ability to improve the overall texture and appearance of the skin.",
    },
    {
      title: 'Pore Refining',
      text: 'Experience the benefits of niacinamide as it works to minimize the appearance of pores, leaving your skin looking refined and smooth.',
    },
    {
      title: 'Oil Control',
      text: 'This serum helps regulate sebum production, providing a mattifying effect that controls excess oil, making it suitable for those with oily or combination skin.',
    },
    {
      title: 'Brightening Complex',
      text: 'Niacinamide is known for its skin-brightening properties, helping to reduce the appearance of dark spots and promote a more even skin tone.',
    },
    {
      title: 'Antioxidant Protection',
      text: 'The serum helps defend your skin against environmental stressors, providing antioxidant protection for a healthier complexion.',
    },
    {
      title: 'Hydration Boost',
      text: "Enjoy a surge of hydration as the serum supports the skin's natural moisture barrier, leaving it plump, supple, and hydrated.",
    },
    {
      title: 'Gentle and Non-Irritating',
      text: 'Pexim Cosmetic understands the importance of gentle skincare. This serum is formulated to be non-irritating and is suitable for all skin types, including sensitive skin.',
    },
    {
      title: 'Paraben-Free and Cruelty-Free',
      text: 'Committed to clean beauty, our formula is free from parabens, and we never test our products on animals.',
    },
  ],
  direction_for_use: [
    'Cleanse your face and pat it dry.',
    'Apply a sum drops of Pexim Cosmetic 10% Niacinamide Face Serum to your face and neck.',
    'Gently massage the serum in upward, circular motions until fully absorbed.',
    'Follow up with your favorite moisturizer.',
    'Use morning and/or evening for optimal results.',
  ],
  additional_text:
    'Revitalize your skin with Pexim Cosmetic 10% Niacinamide Face Serum and embrace a radiant, refined complexion that reflects your natural beauty.',
};

const VITAMIN_C_FOAMING_FACE_WASH = {
  name: 'Vitamin C Foaming Face Wash',
  code: 'vitamin-c-foaming-face-wash',
  image: require('./../assets/images/product-img.png'),
  description:
    'Unveil the radiance of your skin with Pexim Cosmetic Vitamin C Foaming Face Wash. Infused with the power of Vitamin C, this luxurious foaming cleanser is designed to brighten, revitalize, and cleanse your skin for a luminous complexion.',
  key_feature: [
    {
      title: 'Brightening Power of Vitamin C',
      text: 'Pexim Cosmetic harnesses the antioxidant properties of Vitamin C to help brighten dull skin, reduce the appearance of dark spots, and promote an even skin tone.',
    },
    {
      title: 'Gentle Foaming Action',
      text: 'Enjoy a luxurious foaming experience that delicately lifts away impurities, excess oil, and makeup, leaving your skin feeling refreshed and revitalized.',
    },
    {
      title: 'Hydrating Formula',
      text: 'The vitamin C-infused foaming face wash is designed to cleanse without stripping away essential moisture, maintaining a healthy balance for your skin.',
    },
    {
      title: 'Antioxidant Protection',
      text: 'Vitamin C is known for its ability to neutralize free radicals, providing your skin with an added layer of defense against environmental stressors.',
    },
    {
      title: 'Collagen Boost',
      text: 'Stimulate collagen production for firmer, more elastic skin, giving you a youthful and radiant appearance.',
    },
    {
      title: 'Refreshing Citrus Scent',
      text: 'Experience the invigorating aroma of citrus as you indulge in a pampering skincare routine.',
    },
    {
      title: 'Suitable for All Skin Types',
      text: 'Pexim Cosmetic Vitamin C Foaming Face Wash is gentle and suitable for all skin types, including sensitive skin.',
    },
    {
      title: 'Paraben-Free and Cruelty-Free',
      text: 'Committed to clean beauty, our formula is free from parabens, and we never test our products on animals.',
    },
  ],
  direction_for_use: [
    'Wet your face with lukewarm water.',
    'Pump a small amount of Pexim Cosmetic Vitamin C Foaming Face Wash into your palm.',
    'Gently massage the foam onto your damp face in circular motions, avoiding the eye area.',
    'Rinse thoroughly with water.',
    'Follow up with your favorite moisturizer and sunscreen for daytime use.',
  ],
  additional_text:
    'Elevate your skincare routine with Pexim Cosmetic Vitamin C Foaming Face Wash, and let your skin glow with renewed vitality and luminosity.',
};

export const PRODUCTS = [
  ANTI_ACNE_FACE_WASH,
  BRIGHTENING_CREAM,
  D_TAN_SCRUB,
  MOISTURIZING_LOTION_ENRICHED_WITH_ALMOND_AND_HONEY,
  NIACINAMIDE_FACE_SERUM,
  VITAMIN_C_FOAMING_FACE_WASH,
];
