import React, { useState } from 'react';
import { expandMoreIcon } from '../assets/icons/SvgIcons';

const CollapsiblePanel = ({
  label,
  component,
  index,
  containerClassName,
}: {
  label: string;
  component: JSX.Element;
  index?: number;
  containerClassName?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`w-full border-t-skin-secondary border-t-[1px] py-3 ${containerClassName || ''}`}
    >
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          {index && (
            <div
              className={`rounded-full h-8 w-8 mr-4 flex items-center justify-center typo-title-lg pt-1 border-skin-primary border-2 ${
                isOpen ? 'bg-skin-primary !text-skin-on-primary' : ''
              }`}
            >
              {index}
            </div>
          )}
          <div className="typo-title-lg">{label}</div>
        </div>
        <div className="h-4 w-4 text-skin-primary">{expandMoreIcon}</div>
      </div>
      {isOpen && <div className="pt-3">{component}</div>}
    </div>
  );
};

export default CollapsiblePanel;
