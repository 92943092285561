import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { darkModeIcon, lightModeIcon } from '../assets/icons/SvgIcons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/Store';
import { THEME } from '../utils/AppConstants';
import { changeTheme } from '../redux/Preferences';

const LINKS = [
  { label: 'Home', link: '/' },
  { label: 'Product', link: '/product' },
  { label: 'About', link: '/about' },
  { label: 'Contact Us', link: '/contact-us' },
];

const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
    fill="currentColor"
    stroke="currentColor"
  >
    <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
  </svg>
);

const menuIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
  </svg>
);

const Header = () => {
  const preference = useSelector((state: RootState) => state.preference);
  const dispatch = useDispatch();
  const location = useLocation();
  const [openSideBar, setOpenSidebar] = useState(false);

  const themeSwitch = (
    <div
      className="relative h-8 w-16 rounded-full bg-skin-surface flex justify-between items-center"
      onClick={() =>
        dispatch(changeTheme(preference.theme === THEME.light ? THEME.dark : THEME.light))
      }
    >
      <div
        className={`z-10 h-5 w-5 ml-1.5 ${
          preference.theme === THEME.light ? 'text-skin-on-secondary' : 'text-skin-primary'
        }`}
      >
        {lightModeIcon}
      </div>
      <div
        className={`z-10 h-5 w-5 mr-1.5 ${
          preference.theme === THEME.light ? 'text-skin-on-secondary' : 'text-skin-primary'
        }`}
      >
        {darkModeIcon}
      </div>
      <div
        className={`animated-toggle-switch absolute top-0 bg-skin-secondary w-8 h-8 rounded-full ${
          preference.theme === THEME.light ? '' : 'toggle'
        }`}
      />
    </div>
  );

  return (
    <div className='bg-skin-footer'>
      <div
        className={`absolute h-full max-w-[250px] md:hidden bg-skin-footer z-10 py-3 overflow-hidden sidebar ${
          openSideBar ? 'w-[80%]' : 'w-0'
        }`}
      >
        <div className="px-4">
          <div className="text-skin-primary flex justify-end pb-2">
            <div onClick={() => setOpenSidebar(false)}>{closeIcon}</div>
          </div>
          {LINKS.map((link, index) => (
            <Link
              key={`key-${index}`}
              to={link.link}
              onClick={() => setOpenSidebar(false)}
              className={`typo-display6 block whitespace-nowrap pt-2 ${
                location.pathname === link.link ? '!text-skin-secondary' : ''
              } hover:text-skin-secondary mr-6`}
            >
              {link.label}
            </Link>
          ))}
          <div className="pt-6">{themeSwitch}</div>
        </div>
      </div>
      <div className="relative flex justify-between container mx-auto items-center py-4">
        <div className="text-skin-primary block md:hidden w-5" onClick={() => setOpenSidebar(true)}>
          {menuIcon}
        </div>
        <Link to={'/'}>
          <img src={require('../assets/images/logo.png')} className="h-20" alt="logo" />
        </Link>
        <div className="block md:hidden w-5"></div>
        <div className="items-center hidden md:flex">
          <div className="pr-8">
            {LINKS.map((link, index) => (
              <Link
                key={`key-${index}`}
                to={link.link}
                className={`typo-display6 ${
                  location.pathname === link.link ? '!text-skin-secondary' : ''
                } hover:text-skin-secondary mr-6`}
              >
                {link.label}
              </Link>
            ))}
          </div>
          {themeSwitch}
        </div>
      </div>
    </div>
  );
};

export default Header;
