import React from 'react';
import {
  facebookIcon,
  instagramIcon,
  linkedInIcon,
  locationIcon,
  mailIcon,
  phoneIcon,
} from '../assets/icons/SvgIcons';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="w-full bg-skin-footer mt-8">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-16 py-16">
        <div className="col-span-1 md:col-span-3">
          <div className="pb-2">
            <img src={require('../assets/images/logo.png')} className="h-20" alt="logo" />
          </div>
          <div className="typo-body mt-2 max-w-xl">
            Corporate clients and leisure travelers has been relying on Groundlink for dependable
            safe, and professional
          </div>
          <ul className="mt-6">
            {/* <li className="flex items-center">
              <div className="mr-6 h-6 w-6 text-skin-secondary">{locationIcon}</div>
              <div className="typo-body">Brooklyn, New York, United States</div>
            </li> */}
            <Link className="flex mt-2 items-center cursor-pointer" to={'tel:+919724884259'}>
              <div className="mr-6 h-6 w-6 text-skin-secondary">{phoneIcon}</div>
              <div className="typo-body">+91 97248 84259</div>
            </Link>
            <Link
              className="flex mt-2 items-center cursor-pointer"
              to={'mailto:support@peximcosmetic.com'}
            >
              <div className="mr-6 h-6 w-6 text-skin-secondary">{mailIcon}</div>
              <div className="typo-body">support@peximcosmetic.com</div>
            </Link>
          </ul>
        </div>
        {/* <div className="col-span-1 ">
          <div className="typo-display6">Our Products</div>
          <ul className="mt-4">
            <li className="typo-body">Shampoo</li>
            <li className="typo-body  mt-1">Facewash</li>
            <li className="typo-body  mt-1">Mascara</li>
            <li className="typo-body  mt-1">Blush</li>
            <li className="typo-body  mt-1">Foundation</li>
          </ul>
        </div> */}
        <div className="col-span-1">
          <div className="flex md:justify-end">
            <Link
              className="social-media-container"
              to="https://www.instagram.com/pexim_cosmetic/"
              target="_blank"
            >
              {instagramIcon}
            </Link>
            <Link
              className="social-media-container ml-2"
              to="https://www.facebook.com/"
              target="_blank"
            >
              {facebookIcon}
            </Link>
            <Link
              className="social-media-container ml-2"
              to="https://www.linkedin.com/in/pexim-cosmetic-9221a3298/"
              target="_blank"
            >
              {linkedInIcon}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
