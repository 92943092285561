import React, { useEffect, useRef, useState } from 'react';

const Offers = () => {
  const offerImages = [
    require('./../assets/images/offer-banner.webp'),
    require('./../assets/images/offer-banner.webp'),
  ];

  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isVisible) {
          setIsVisible(entry.isIntersecting);
        }
      },
      { threshold: 0 } // Adjust the threshold as needed
    );

    if (isVisible && targetRef.current) {
      observer.unobserve(targetRef.current);
    } else if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [isVisible]);

  return (
    <div className="container mx-auto" ref={targetRef}>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 gap-8 py-8 ${
          isVisible ? 'animation-fade-in' : 'invisible'
        }`}
      >
        {offerImages.map((imageSrc, index) => (
          <div
            className={`col-span-1 rounded-md overflow-hidden ${!isVisible && 'hidden'}`}
            key={index}
          >
            <img className="object-cover h-full w-full" src={imageSrc} alt={'banner'} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Offers;
