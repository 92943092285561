import React from 'react';
import Home from './screens/Home';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './styles/index.css';
import './styles/custom.css';
import ProductDetailPage from './screens/ProductDetailPage';
import Layout from './components/Layout';
import { useSelector } from 'react-redux';
import { RootState } from './redux/Store';
import { Product } from './screens/Product';
import { About } from './screens/About';
import { ContactUs } from './screens/ContactUs';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: '/product',
        element: <Product />,
      },
      {
        path: '/product/:id',
        element: <ProductDetailPage />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/contact-us',
        element: <ContactUs />,
      },
    ],
  },
]);

function App() {
  const preference = useSelector((state: RootState) => state.preference);

  return (
    <div className={`${preference.theme} bg-skin-background h-screen overflow-scroll`}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
