import { FormikProps } from 'formik';
import React from 'react';

interface ICustomInput<T>
  extends FormikProps<T>,
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  fieldName: keyof T;
  label: string;
}

export const CustomInput = <T extends object>(props: ICustomInput<T>) => {
  const { fieldName, label, handleChange, handleBlur, values, errors, touched, ...inputProps } =
    props;

  return (
    <div className="mb-6">
      <div className="typo-title">{label}</div>
      <input
        {...inputProps}
        name={fieldName as string}
        className={`border-2 px-3 rounded-[4px] h-[48px] w-full focus:outline-none border-skin-on-surface focus:border-skin-secondary ${inputProps.className}`}
        placeholder={label}
        value={values[fieldName] as any}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {touched[fieldName] && errors[fieldName] && (
        <div className="typo-error mt-1">{errors[fieldName] as any}</div>
      )}
    </div>
  );
};
