import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PRODUCTS } from '../assets/ProductData';

const PRODUCT_LIST = [
  {
    image: require('./../assets/images/product.webp'),
    name: 'Lorem, ipsum dolor sit ame elit.',
    display_price: '499',
    price: '699',
  },
  {
    image: require('./../assets/images/product.webp'),
    name: 'Lorem, ipsum dolor sit ame elit.',
    display_price: '499',
    price: '699',
  },
  {
    image: require('./../assets/images/product.webp'),
    name: 'Lorem, ipsum dolor sit ame elit.',
    display_price: '499',
    price: '699',
  },
  {
    image: require('./../assets/images/product.webp'),
    name: 'Lorem, ipsum dolor sit ame elit.',
    display_price: '499',
    price: '699',
  },
  {
    image: require('./../assets/images/product.webp'),
    name: 'Lorem, ipsum dolor sit ame elit.',
    display_price: '499',
    price: '699',
  },
  {
    image: require('./../assets/images/product.webp'),
    name: 'Lorem, ipsum dolor sit ame elit.',
    display_price: '499',
    price: '699',
  },
  // {
  //   image: require('./../assets/images/product.webp'),
  //   name: 'Lorem, ipsum dolor sit ame elit.',
  //   display_price: '499',
  //   price: '699',
  // },
  // {
  //   image: require('./../assets/images/product.webp'),
  //   name: 'Lorem, ipsum dolor sit ame elit.',
  //   display_price: '499',
  //   price: '699',
  // },
];

interface IProductViewPorps {
  name: string;
  code: string;
  image: string;
  // display_price: string;
  // price: string;
}
const ProductView = ({ name, image, code }: IProductViewPorps) => {
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isVisible) {
          setIsVisible(entry.isIntersecting);
        }
      },
      { threshold: 0 } // Adjust the threshold as needed
    );

    if (isVisible && targetRef.current) {
      observer.unobserve(targetRef.current);
    } else if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [isVisible]);

  const navigate = useNavigate();

  return (
    <div
      ref={targetRef}
      className={`col-span-1 hover:scale-105`}
      onClick={() => navigate('/product/' + code)}
    >
      <div
        className={`${
          isVisible ? 'animation-fade-in' : 'invisible'
        } rounded-md shadow-sm border border-skin-surface overflow-hidden bg-skin-surface`}
      >
        <img className="object-cover w-full" alt="product" src={image} />
        <div className="p-3">
          <div className="text-center typo-title-lg">{name}</div>
          <div className="flex justify-center pt-3">
            {/* <div className="typo-body-lg !text-skin-secondary">₹{display_price}</div> */}
            {/* <div className="typo-body-lg ml-2 line-through">₹{price}</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const Products = () => {
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isVisible) {
          setIsVisible(entry.isIntersecting);
        }
      },
      { threshold: 0 } // Adjust the threshold as needed
    );

    if (isVisible && targetRef.current) {
      observer.unobserve(targetRef.current);
    } else if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [isVisible]);

  return (
    <div className="container mx-auto" ref={targetRef}>
      <div
        className={`product-list-header text-center pt-12 pb-5 ${
          isVisible ? 'animation-fade-in' : 'invisible'
        }`}
      >
        FEATURED PRODUCTS
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {PRODUCTS.map((item, index) => (
          <ProductView {...item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Products;
