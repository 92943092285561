import React, { useEffect, useState } from 'react';
import CollapsiblePanel from './CollapsiblePanel';
import { useLocation, useParams } from 'react-router-dom';
import { PRODUCTS } from '../assets/ProductData';
import { IProduct } from '../utils/AppTypes';

const ProductPreview = () => {
  const PRODUCT_DETAILS = {
    name: 'Lorem, ipsum dolor sit ame elit',
    imageUrl: [require('../assets/images/preview-product.webp')],
    price: '699',
    display_price: '499',
    about:
      "Add an extra dose of style with this raw look henley t-shirt from the house of Tinted. Team this T-shirt with distressed jeans and leather sandals for a relaxed and cool look.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    description:
      "Add an extra dose of style with this raw look henley t-shirt from the house of Tinted. Team this T-shirt with distressed jeans and leather sandals for a relaxed and cool look.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  };

  const [product, setProduct] = useState<IProduct | null>();
  const params = useParams();

  useEffect(() => {
    const findProduct = PRODUCTS.find((item) => item.code === params?.id);
    if (findProduct) {
      setProduct(findProduct);
    }
  }, []);

  return (
    <div className="container mx-auto pt-8 sm:h-full">
      {product && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:max-h-screen">
          <div className="col-span-1">
            <div className="rounded-sm overflow-hidden p-3 bg-skin-footer w-full aspect-[590/573]">
              <img
                className="object-cover h-full w-full rounded-sm"
                src={product.image}
                alt={product.name}
              />
            </div>
          </div>
          <div className="col-span-1 md:max-h-screen overflow-scroll pt-4">
            <div className="typo-display4">{product.name}</div>
            {/* <div className="flex pt-3">
              <div className="typo-body-lg !text-skin-secondary">
                ₹{PRODUCT_DETAILS.display_price}
              </div>
              <div className="typo-body-lg ml-2 line-through">₹{PRODUCT_DETAILS.price}</div>
            </div> */}
            <div className="pt-3">
              <div className="typo-body">{product.description}</div>
            </div>
            <div className="btn-primary mt-6">Inquiry Now</div>
            <div className="pt-12">
              {product.key_feature.map((keyFeature) => (
                <CollapsiblePanel
                  label={keyFeature.title}
                  containerClassName={'py-4'}
                  component={<div className="typo-body">{keyFeature.text}</div>}
                />
              ))}
              <div className="w-full border-t-skin-secondary border-t-[1px]" />
            </div>
            <div className="pt-12">
              <div className="typo-display5 text-skin-primary">Direction Of Use</div>
              {product.direction_for_use.map((directionForUse) => (
                <div className="flex pt-3">
                  <div className="w-3 h-3 bg-skin-secondary rounded-full mt-1" />
                  <div className="typo-body text-skin-primary pl-3">{directionForUse}</div>
                </div>
              ))}
            </div>
            <div className="pt-12">
              <div className="typo-body-lg text-skin-primary">{product.additional_text}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPreview;
