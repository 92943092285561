import { createSlice } from '@reduxjs/toolkit';
import { THEME } from '../utils/AppConstants';

export interface IPreferenceState {
  theme: any;
  country: string;
}

const initialState: IPreferenceState = {
  theme: THEME.dark,
  country: 'US',
};

export const PreferenceSlice = createSlice({
  name: 'Preference',
  initialState,
  reducers: {
    changeTheme: (state, data) => {
      state.theme = data.payload;
    },
  },
});

export const { changeTheme } = PreferenceSlice.actions;

export default PreferenceSlice.reducer;
