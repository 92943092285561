import React from 'react';
import Products from '../components/Products';
import Offers from '../components/Offers';

export const Product = () => {
  return (
    <div>
      <Offers />
      <Products />
    </div>
  );
};
