import React from 'react';

export const About = () => {
  return (
    <div className="container mx-auto flex justify-center">
      <div className="max-w-[1000px] text-center">
        <div className="pt-20 typo-body-lg">
          PEXIM COSMETIC is a skin care products company that is dedicated to providing high-quality
          skincare products to its customers. Our company is committed to using only the finest
          ingredients for our products, ensuring that our customers receive the best possible
          results. We understand that your skin is unique, and that is why we have developed a wide
          range of products to cater to different skin types and concerns.
        </div>
        <div className="pt-20 typo-body-lg">
          Our products are formulated to address a variety of skin concerns, including acne, aging,
          and dryness. We use natural ingredients such as aloe vera, shea butter, and jojoba oil to
          nourish and hydrate the skin, leaving it looking and feeling healthy. Our products are
          also free from harmful chemicals that can damage the skin, making them safe for even the
          most sensitive skin types.
        </div>
        <div className="pt-20 typo-body-lg">
          At PEXIM COSMETIC, we believe that everyone deserves to have healthy, glowing skin. That
          is why our products are affordable and accessible to everyone. We are committed to
          providing our customers with exceptional service and support, and we are always here to
          answer any questions or concerns you may have.
        </div>
        <div className="pt-20 typo-body-lg">
          Thank you for considering PEXIM COSMETIC for your skincare needs. We are confident that
          our products will exceed your expectations and help you achieve the beautiful, healthy
          skin you deserve.
        </div>
        {/* <div className="pt-20 typo-display4">Our Principles</div>
        <div className="pt-12 typo-display6">Quality First</div>
        <div className="pt-2 typo-body">
          At Pexim Cosmetics, we prioritize quality above all else. Our products are carefully
          crafted using the finest ingredients to ensure optimal results and customer satisfaction.
        </div>
        <div className="pt-12 typo-display6">Enhancing Natural Beauty</div>
        <div className="pt-2 typo-body">
          We believe in enhancing your natural beauty rather than masking it. Our cosmetic products
          are designed to accentuate your best features and bring out your unique radiance.
        </div>
        <div className="pt-12 typo-display6">Self-Care at its Finest</div>
        <div className="pt-2 typo-body">
          Indulge in the art of self-care with Pexim Cosmetics. Our collection offers a luxurious
          experience that allows you to pamper yourself and prioritize your well-being.
        </div> */}
      </div>
    </div>
  );
};
