import React from 'react';

// const PromotionSlide = (
//   <div className="h-[595px] relative overflow-hidden">
//     <div className="h-full w-full">
//       <div className="grid grid-cols-2 container mx-auto h-full z-10">
//         <div className="col-span-1 flex items-center">
//           <div className="animation-fade-in">
//             <div className="typo-display2">Discover the Beauty Within</div>
//             <div className="typo-body-lg mt-6">
//               50% OFF on the most popular cosmetic brands. Order all classy products today!
//             </div>
//             <div className="btn-primary"><div>SHOP NOW</div></div>
//           </div>
//         </div>
//         <div className="col-span-1"></div>
//       </div>
//       <div className="absolute top-0 h-full w-full -z-10">
//         <img
//           className="object-cover h-full w-full"
//           src={require('./../assets/images/promotion-banner.png')}
//           alt={'banner'}
//         />
//       </div>
//     </div>
//   </div>
// );

const BrandPromotion = () => {
  return (
    <div>
      <img
        className="object-cover h-full w-full"
        src={require('./../assets/images/promotion-banner.png')}
        alt={'banner'}
      />
    </div>
  );
};

export default BrandPromotion;
